import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

export function ParticipationRules() {
  return (
    <List sx={{ listStyle: 'decimal' }}>
      <ListItem sx={{ display: 'list-item' }}>Setiap kontinjen sekolah hanya boleh menghantar 1 pasukan setiap satu untuk peringkat lelaki menengah rendah, lelaki menengah atas, wanita menengah rendah, wanita menengah atas bagi kategori utama.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Setiap kontinjen UAS hanya boleh menghantar 1 pasukan setiap satu untuk peringkat lelaki dan wanita bagi kategori utama.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Setiap cawangan boleh menghantar lebih dari satu pasukan bagi kategori utama.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Kesemua kontinjen boleh menghantar lebih dari satu penyertaan untuk kategori terbuka.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Acara 5 (6 buah seragam) dan Acara 10 (Gerak Silat Sepasang) untuk peserta yang belum tamat tetapi telah sampai ke peringkat buah jatuh yang kesepuluh.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Acara 4 (4 serang satu) dan Acara 11 (Gerak Silat Bertiga) untuk peserta yang belum tamat tetapi sekurang kurangnya telah sampai ke peringkat buah jatuh yang kedua puluh.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Tidak lebih daripada 3 orang Jurulatih Tahap 3 bagi kategori utama. Tiada had jurulatih bagi penyertaan di kategori terbuka.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Hanya Tenaga Pengajar yang berasal dari kelas masing-masing yang boleh mewakili pasukan cawangan, universiti awam/universiti swasta dan sekolah tersebut bagi kategori utama.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Perkara #8 tidak tertakluk kepada kategori terbuka.</ListItem>
    </List>
  );
}