import { ExpandMore } from "@mui/icons-material";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

export function PrefabAccordion(props: AccordionProps & { title: string }) {
  return (
    <Accordion {...props}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {props.title}
      </AccordionSummary>
      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}