import { Settings } from "../models";
import { RestDataStore } from "./RestDataStore";

export class SettingsDataStore extends RestDataStore<Settings, Omit<Settings, 'id'>> {
  constructor(token?: string) {
    super('settings', token);
  }

  public async getLatest() {
    const response = await fetch(`${this.apiUrl}/${this.collectionName}`, {
      method: 'GET',
      headers: this.getHeaders()
    });
    if (response.ok) {
      const results: Settings = await response.json();
      return results;
    } else {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
  }
}