import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

export function EventTable() {
  const userContext = useContext(UserContext);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Kepakaran</TableCell>
            <TableCell>Utama</TableCell>
            <TableCell>Terbuka</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Tamat</TableCell>
            <TableCell>
              <List>
                {userContext.events.map(
                  (e, i) => (e.graduatesOnly && !e.open) && <ListItem key={`event-item${i}`} disableGutters>Acara {i+1}: {e.name} — {e.participants} peserta, {e.timeLimit} saat</ListItem>
                )}
              </List>
            </TableCell>
            <TableCell>
              <List>
                {userContext.events.map(
                  (e, i) => (e.graduatesOnly && e.open) && <ListItem key={`event-item${i}`} disableGutters>Acara {i+1}: {e.name} — {e.participants} peserta, {e.timeLimit} saat</ListItem>
                )}
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Belum Tamat</TableCell>
            <TableCell>
              <List>
                {userContext.events.map(
                  (e, i) => (!e.graduatesOnly && !e.open) && <ListItem key={`event-item${i}`} disableGutters>Acara {i+1}: {e.name} — {e.participants} peserta, {e.timeLimit} saat</ListItem>
                )}
              </List>
            </TableCell>
            <TableCell>
              <List>
                {userContext.events.map(
                  (e, i) => (!e.graduatesOnly && e.open) && <ListItem key={`event-item${i}`} disableGutters>Acara {i+1}: {e.name} — {e.participants} peserta, {e.timeLimit} saat</ListItem>
                )}
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}