import List from "@mui/material/List";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Circle } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export function Categories() {
  const userContext = useContext(UserContext);
  return (
    <>
      <Typography>Bagi setiap peringkat, ia tidak akan dipertandingkan sekiranya penyertaan kurang daripada 4 pasukan.</Typography>
      <List>
        {userContext.categories.map(
          c => (
            <ListItem key={`category-item-${c.id}`}>
              <ListItemIcon><Circle /></ListItemIcon>
              <ListItemText>{c.name}</ListItemText>
            </ListItem>
          )
        )}
      </List>
    </>
  );
}