import { DataStore, TDocument } from "../models";

export class RestDataStore<TData extends TDocument, TDto> implements DataStore<TData, TDto> {
  protected apiUrl: string = process.env.REACT_APP_API_URL ?? "";

  constructor(protected collectionName: string, protected token?: string) {}
  
  public async getAll(filter?: (item: TData) => boolean): Promise<TData[]> {
    const response = await fetch(`${this.apiUrl}/${this.collectionName}`, {
      method: 'GET',
      headers: this.getHeaders()
    });
    if (response.ok) {
      const results: TData[] = await response.json();
      if (filter) {
        return results.filter(filter);
      }
      return results;
    } else {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
  }

  public async get(id: string): Promise<TData | undefined> {
    const response = await fetch(`${this.apiUrl}/${this.collectionName}/${id}`, {
      method: 'GET',
      headers: this.getHeaders()
    });
    if (response.ok) {
      const results: TData | undefined = await response.json();
      return results;
    } else {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
  }

  public async create(details: TDto): Promise<TData | undefined> {
    const response = await fetch(`${this.apiUrl}/${this.collectionName}`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(details)
    });
    if (response.ok) {
      const results: TData | undefined = await response.json();
      return results;
    } else {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
  }

  public async update(id: string, details: Partial<TDto>): Promise<TData | undefined> {
    const response = await fetch(`${this.apiUrl}/${this.collectionName}/${id}`, {
      method: 'PATCH',
      headers: this.getHeaders(),
      body: JSON.stringify(details)
    });
    if (response.ok) {
      const results: TData | undefined = await response.json();
      return results;
    } else {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
  }

  public async delete(id: string): Promise<boolean> {
    const response = await fetch(`${this.apiUrl}/${this.collectionName}/${id}`, {
      method: 'DELETE',
      headers: this.getHeaders()
    });
    if (response.ok) {
      const results: boolean = Boolean(await response.text());
      return results;
    } else {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
  }

  protected getHeaders() {
    const output: HeadersInit = {
      'Content-Type': 'application/json'
    };
    if (!!this.token) {
      output.Authentication = `Bearer ${this.token}`;
    }
    return output;
  }
}