import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export function ScoringTable() {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Kategori</TableCell>
            <TableCell>Markah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Kaedah</TableCell>
            <TableCell>20</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Persembahan</TableCell>
            <TableCell>15</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Keseluruhan</TableCell>
            <TableCell>15</TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Jumlah</TableCell>
            <TableCell>50</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}