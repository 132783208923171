import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { Settings } from "../models";
import { SettingsDataStore } from "../data/SettingsDataStore";

type SettingsFunctions = {
  settings: Settings;
  setSettings: (s: Settings) => Promise<Settings | undefined>;
};

export const SettingsContext = createContext<SettingsFunctions>({
  settings: {
    id: '',
    registrationDeadline: new Date(),
    teamEditDeadline: new Date()
  },
  setSettings: () => new Promise<Settings | undefined>(resolve => resolve(undefined))
});

export function SettingsProvider({
  children
}: {
  children: ReactNode
}) {
  const defaultSettings: Settings = useMemo(() => ({
    id: '0',
    registrationDeadline: new Date(2024, 6, 1),
    teamEditDeadline: new Date(2024, 6, 1)
  }), []);
  const settingsDb: SettingsDataStore = useMemo(() => new SettingsDataStore(), []);
  const [settings, setSettings] = useState<Settings>(defaultSettings);

  useEffect(() => {
    settingsDb.getLatest().then(
      result => {
        setSettings({
          ...result,
          registrationDeadline: new Date(result.registrationDeadline),
          teamEditDeadline: new Date(result.teamEditDeadline)
        });
      }
    ).catch(e => {
      console.error(e);
      settingsDb.create({
        registrationDeadline: new Date(2024, 6, 1),
        teamEditDeadline: new Date(2024, 6, 1)
      }).then(created => {
        if (!!created) {
          setSettings({
            ...created,
            registrationDeadline: new Date(created.registrationDeadline),
            teamEditDeadline: new Date(created.teamEditDeadline)
          });
        }
      }).catch(e2 => {
        console.error(e2);
      });
    });
  });

  const handleSetSettings = useCallback((v: Settings) => new Promise<Settings | undefined>(resolve => {
    settingsDb.update(settings.id, v).then(result => {
      if (!!result) {
        setSettings(result);
        resolve(result);
      }
    });
  }), [settings, settingsDb]);

  return (
    <SettingsContext.Provider value={{ settings, setSettings: handleSetSettings }}>
      {children}
    </SettingsContext.Provider>
  );
}