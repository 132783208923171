import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { PrefabAccordion } from "../components/PrefabAccordion";
import { GlossaryTable } from "../components/rules/GlossaryTable";
import { ExampleVideoTabs } from "../components/rules/ExampleVideoTabs";
import { TournamentEquipments } from "../components/rules/TournamentEquipments";
import { Categories } from "../components/rules/Categories";
import { Fees } from "../components/rules/Fees";
import { ParticipationRules } from "../components/rules/ParticipationRules";
import { EventTable } from "../components/rules/EventTable";
import { ScoringTable } from "../components/rules/ScoringTable";
import { TeamPoints } from "../components/rules/TeamPoints";
import { EventFormat } from "../components/rules/EventFormat";
import { AboutObjections } from "../components/rules/AboutObjections";

export default function DocumentationPage() {
  const [openAccordion, setOpenAccordion] = useState<string>();
  const handleToggle = (name: string) => {
    setOpenAccordion(o => o === name ? '' : name);
  };
  
  return (
    <Stack spacing={2}>
      <Typography variant='h2' noWrap>Peraturan Kejohanan</Typography>
      <PrefabAccordion title='Istilah' expanded={openAccordion === 'istilah'} onChange={() => handleToggle('istilah')}>
        <GlossaryTable />
      </PrefabAccordion>
      <PrefabAccordion title='Kelengkapan pertandingan' expanded={openAccordion === 'kelengkapan'} onChange={() => handleToggle('kelengkapan')}>
        <TournamentEquipments />
      </PrefabAccordion>
      <PrefabAccordion title='Peringkat yang dipertandingkan' expanded={openAccordion === 'peringkat'} onChange={() => handleToggle('peringkat')}>
        <Categories />
      </PrefabAccordion>
      <PrefabAccordion title='Yuran' expanded={openAccordion === 'yuran'} onChange={() => handleToggle('yuran')}>
        <Fees />
      </PrefabAccordion>
      <PrefabAccordion title='Peraturan penyertaan' expanded={openAccordion === 'penyertaan'} onChange={() => handleToggle('penyertaan')}>
        <ParticipationRules />
      </PrefabAccordion>
      <PrefabAccordion title='Acara-acara yang dipertandingkan' expanded={openAccordion === 'acara'} onChange={() => handleToggle('acara')}>
        <EventTable />
      </PrefabAccordion>
      <PrefabAccordion title='Penilaian' expanded={openAccordion === 'penilaian'} onChange={() => handleToggle('penilaian')}>
        <ScoringTable />
      </PrefabAccordion>
      <PrefabAccordion title='Penentuan kemenangan' expanded={openAccordion === 'penentuan'} onChange={() => handleToggle('penentuan')}>
        <TeamPoints />
      </PrefabAccordion>
      <PrefabAccordion title='Format acara' expanded={openAccordion === 'format'} onChange={() => handleToggle('format')}>
        <EventFormat />
      </PrefabAccordion>
      <PrefabAccordion title='Berkenaan bantahan' expanded={openAccordion === 'bantahan'} onChange={() => handleToggle('bantahan')}>
        <AboutObjections />
      </PrefabAccordion>
      <PrefabAccordion title='Video-video contoh' expanded={openAccordion === 'video'} onChange={() => handleToggle('video')}>
        <ExampleVideoTabs />
      </PrefabAccordion>
    </Stack>
  );
}