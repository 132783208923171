import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer, { TableContainerProps } from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export function GlossaryTable(props: TableContainerProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Perkataan</TableCell>
            <TableCell>Definisi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Kontinjen</TableCell>
            <TableCell>Keseluruhan pasukan-pasukan yang bernaung dibawah satu nama yang sama (Contoh: kontinjen SMK Syeikh Abdul Ghani).</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pasukan</TableCell>
            <TableCell>Sekumpulan peserta yang bertanding mengikut peringkat di bawah satu kontinjen (Contoh: pasukan Menengah Rendah Lelaki, SMK Syeikh Abdul Ghani). Setiap kontinjen hanya boleh menghantar satu pasukan bagi penyertaan di kategori utama.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Penyertaan</TableCell>
            <TableCell>Sepasang/3 orang pesilat yang menyertai kategori terbuka. Setiap kontinjen boleh menghantar lebih dari 1 penyertaan (contoh: Sepasang pisau SMK Syeikh Abdul Ghani A, Sepasang pisau SMK Syeikh Abdul Ghani B, dsb).</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Peringkat</TableCell>
            <TableCell>Pembahagian pasukan mengikut jenis kelas (cawangan, universiti, menengah tinggi, menengah rendah) dan jantina (lelaki, wanita).</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Kategori</TableCell>
            <TableCell>Dipecahkan kepada 2, iaitu Kategori Utama dan Kategori Terbuka.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Acara</TableCell>
            <TableCell>Acara yang dipertandingkan seperti Umum Senjata, Gerak Senam Cekak Hanafi dan sebagainya.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Arena</TableCell>
            <TableCell>Lokasi dalam kejohanan di mana setiap acara dijalankan. Setiap arena mempunyai 3 orang juri termasuk Ketua Juri.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pengurus Pasukan</TableCell>
            <TableCell>Orang yang bertanggungjawab untuk menentukan setiap pasukan dan peserta membuat persembahan mengikut peraturan dan waktunya. Seorang pengurus boleh menguruskan lebih dari satu pasukan. Tidak boleh menyertai acara.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ketua Pasukan</TableCell>
            <TableCell>Orang yang bertanggungjawab mengetuai peserta peserta di bawah pasukannya. Boleh turut serta dalam acara.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Kategori Utama</TableCell>
            <TableCell>Acara-acara yang dipertandingkan secara berkumpulan dan akan dikira dalam penentuan juara keseluruhan. Hanya satu pasukan bagi setiap peringkat dalam setiap kontinjen kecuali peringkat cawangan.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Kategori Terbuka</TableCell>
            <TableCell>Acara-acara yang dipertandingkan secara berpasang/bertiga. Setiap pasukan boleh menghantar lebih dari satu penyertaan bagi setiap acara terbuka.</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}