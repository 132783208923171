import { Credentials, SessionToken, User } from "../models";
import { RestDataStore } from "./RestDataStore";

export class UserDataStore extends RestDataStore<User, Omit<User, 'id'>> {
  constructor(protected token?: string) {
    super('user', token);
  }

  public async login(credentials: Credentials) {
    const response = await fetch(`${this.apiUrl}/${this.collectionName}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    });
    if (response.ok) {
      const results: SessionToken | undefined = await response.json();
      return results;
    } else if (response.status === 400) {
      throw new Error('No. IC atau kata laluan salah.');
    } else {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
  }
}