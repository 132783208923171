import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { EventDetails } from "./EventDetails";

export function EventFormat() {
  const userContext = useContext(UserContext);
  const [open, setOpen] = useState<string>();
  const handleToggle = (name: string) => {
    setOpen(o => o === name ? '' : name);
  };
  return (
    <>
      <Typography>Format umum bagi kesemua acara:</Typography>
      <List sx={{ listStyle: 'decimal' }}>
        <ListItem sx={{ display: 'list-item' }}>Peserta persembahan bergerak ke arena persembahan.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Peserta persembahan bersedia untuk memulakan persembahan.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Ketua Juri akan memberikan arahan kepada peserta.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Hormat pasukan dilakukan.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Persembahan dimulakan.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Tanda ingatan masa 10 (sepuluh) saat sebelum waktu persembahan tamat dibunyikan bagi komponen yang ditetapkan waktu persembahan.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Hormat pasukan dilakukan setelah selesai persembahan.</ListItem>
      </List>
      <Typography>Format khusus bagi setiap acara:</Typography>
      {
        userContext.events.length > 0 && (
          <EventDetails event={userContext.events[0]} index={0} expanded={open === 'event0'} onChange={() => handleToggle('event0')}>
            <Typography>Senarai Buah Jatuh yang mesti dipakai:</Typography>
            <List sx={{ listStyle: 'decimal' }}>
              <ListItem sx={{ display: 'list-item' }}>Buah Keputusan Sendeng Atas</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Paras (Pecahan Pertama)</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Pertama</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Hempok (Pecahan Pertama)</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Kuntau Jatuh (Kiri)</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Keputusan Lintau (Kiri)</ListItem>
            </List>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 1 && (
          <EventDetails event={userContext.events[1]} index={1} expanded={open === 'event1'} onChange={() => handleToggle('event1')}>
            <Typography>Perlu gabungan tangan kosong dan sekurang-kurangnya 2 senjata.</Typography>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 2 && (
          <EventDetails event={userContext.events[2]} index={2} expanded={open === 'event2'} onChange={() => handleToggle('event2')}>
            <Typography>Perlu gabungan tangan kosong dan sekurang-kurangnya 2 senjata.</Typography>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 3 && (
          <EventDetails event={userContext.events[3]} index={3} expanded={open === 'event3'} onChange={() => handleToggle('event3')}>
            <Typography>4 serangan satu persatu & 1 serangan serentak.</Typography>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 4 && (
          <EventDetails event={userContext.events[4]} index={4} expanded={open === 'event4'} onChange={() => handleToggle('event4')}>
            <Typography>Senarai Buah Jatuh yang mesti dipakai:</Typography>
            <List sx={{ listStyle: 'decimal' }}>
              <ListItem sx={{ display: 'list-item' }}>Buah Kidung Kiri</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Kuntau Kanan Gantung</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Kilas Hadapan</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Hentak @ Keputusan Silat Harimau</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Kuntau Kiri Gantung</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Buah Keputusan Gayong Fatani (Pecahan Kedua)</ListItem>
            </List>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 5 && (
          <EventDetails event={userContext.events[5]} index={5} expanded={open === 'event5'} onChange={() => handleToggle('event5')}>
            <Typography>Turutan serangan:</Typography>
            <List sx={{ listStyle: 'decimal' }}>
              <ListItem sx={{ display: 'list-item' }}>Dari arah hadapan.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Dari arah kiri (simbat).</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Dari arah bawah.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Bebas — 2 serangan dari pelbagai kaedah.</ListItem>
            </List>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 6 && (
          <EventDetails event={userContext.events[6]} index={6} expanded={open === 'event6'} onChange={() => handleToggle('event6')}>
            <Typography>Turutan serangan:</Typography>
            <List sx={{ listStyle: 'decimal' }}>
              <ListItem sx={{ display: 'list-item' }}>Penyepak minang.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Penyepak luar.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Penyepak <i>reverse kick</i>.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Penyepak sisi.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Bebas — 1 serangan dari pelbagai kaedah.</ListItem>
            </List>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 7 && (
          <EventDetails event={userContext.events[7]} index={7} expanded={open === 'event7'} onChange={() => handleToggle('event7')}>
            <Typography>Turutan serangan:</Typography>
            <List sx={{ listStyle: 'decimal' }}>
              <ListItem sx={{ display: 'list-item' }}>Dari arah atas.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Dari arah kanan.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Dari arah kiri.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Menggunakan tongkat panjang.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Bebas — 1 serangan dari pelbagai kaedah.</ListItem>
            </List>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 8 && (
          <EventDetails event={userContext.events[8]} index={8} expanded={open === 'event8'} onChange={() => handleToggle('event8')}>
            <Typography>Kru yang menyerang hero tidak mesti mempunyai lading atau berbusana lengkap.</Typography>
            <Typography>Turutan serangan:</Typography>
            <List sx={{ listStyle: 'decimal' }}>
              <ListItem sx={{ display: 'list-item' }}>Senaman lading (hero sahaja).</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Dari arah hadapan.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Dari arah bawah.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Dari arah kiri.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Bebas — 1 serangan dari pelbagai kaedah.</ListItem>
            </List>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 9 && (
          <EventDetails event={userContext.events[9]} index={9} expanded={open === 'event9'} onChange={() => handleToggle('event9')}>
            <Typography>Gerak silat mesti mengandungi kombinasi yang berikut (tidak mengikut turutan):</Typography>
            <List sx={{ listStyle: 'circle' }}>
              <ListItem sx={{ display: 'list-item' }}>Kesemua kaedah.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Minimum 4 Buah Asas (1-10)</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Minimum 4 Buah Jatuh (1-10)</ListItem>
            </List>
            <Typography>Peserta mesti memberi turutan gerakan kepada setiap juri (jumlah 3 salinan).</Typography>
          </EventDetails>
        )
      }
      {
        userContext.events.length > 10 && (
          <EventDetails event={userContext.events[10]} index={10} expanded={open === 'event10'} onChange={() => handleToggle('event10')}>
            <Typography>Gerak silat mesti mengandungi kombinasi yang berikut (tidak mengikut turutan):</Typography>
            <List sx={{ listStyle: 'circle' }}>
              <ListItem sx={{ display: 'list-item' }}>Kesemua kaedah.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Minimum 4 Buah Asas (11-20)</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Minimum 4 Buah Jatuh (11-20)</ListItem>
            </List>
            <Typography>Peserta mesti memberi turutan gerakan kepada setiap juri (jumlah 3 salinan).</Typography>
          </EventDetails>
        )
      }
    </>
  );
}