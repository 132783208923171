import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { SettingsContext } from "../contexts/SettingsContext";

export default function HomePage() {
  const userContext = useContext(UserContext);
  const settingsContext = useContext(SettingsContext);
  return (
    <>
      <Typography variant="h2" noWrap>Laman Utama</Typography>
      {!!userContext.token && <Typography variant="body1">Selamat datang, {userContext.token?.name}!</Typography>}
      <br />
      <Typography variant="h4">Tarikh Akhir</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Jenis</strong></TableCell>
              <TableCell><strong>Tarikh</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Pendaftaran</TableCell>
              <TableCell>{settingsContext.settings.registrationDeadline.toLocaleDateString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Suntingan Pasukan</TableCell>
              <TableCell>{settingsContext.settings.teamEditDeadline.toLocaleDateString()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}