import { ContingentDto } from "../models";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useContext, useMemo, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import Autocomplete from "@mui/material/Autocomplete";

export function ContingentForm(
  {
    onSave,
    onCancel,
    disabled
  }: {
    onSave: (output: ContingentDto) => void;
    onCancel?: () => void;
    disabled?: boolean;
  }
) {
  const userContext = useContext(UserContext);
  const [data, setData] = useState<ContingentDto>({
    name: '',
    categories: []
  });
  const formValuesAreValid = useMemo(
    () => !!data.name && data.categories.length > 0,
    [data]
  );
  return (
    <form autoComplete="off">
      <Stack spacing={2}>
        <TextField label='Nama Kontinjen' type='text' required value={data.name} onChange={(e) => setData(d => ({ ...d, name: e.target.value }))} margin="normal" variant="standard" />
        <Autocomplete
          multiple
          value={userContext.categories.filter(x => data.categories.includes(x.id))}
          onChange={(_, v) => setData(d => ({ ...d, categories: v.map(x => x.id) }))}
          options={userContext.categories}
          getOptionLabel={option => option.name}
          getOptionKey={option => option.id}
          renderInput={(params: TextFieldProps) => <TextField {...params} label="Peringkat penyertaan" />}
          />
        <Stack spacing={2} direction='row-reverse'>
          {!!onCancel && <Button variant='text' onClick={onCancel}>Batal</Button>}
          <Button disabled={!formValuesAreValid || disabled} variant='contained' onClick={() => onSave(data)}>Simpan</Button>
        </Stack>
      </Stack>
    </form>
  );
}