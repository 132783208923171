import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

export function AboutObjections() {
  return (
    <List sx={{ listStyle: 'decimal' }}>
      <ListItem sx={{ display: 'list-item' }}>Setiap bantahan boleh dibuat melalui Borang Bantahan.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Borang hendaklah diajukan terus kepada Setiausaha Lembaga Pelajaran dan Tatatertib (LPT) atau wakil yang dilantik oleh beliau dalam masa <strong>10 minit</strong> selepas persembahan.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Wang pertaruhan sebanyak <strong>RM500</strong> hendaklah disediakan. Wang tersebut akan dikembalikan sekiranya bantahan diterima.</ListItem>
      <ListItem sx={{ display: 'list-item' }}>Walau bagaimanapun, bantahan terhadap fakta-fakta pengadilan yang dibuat oleh juri adalah <strong>tidak</strong> dibenarkan.</ListItem>
    </List>
  );
}