import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useCallback, useContext, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomTabPanel from "../components/CustomTabPanel";
import Button from "@mui/material/Button";
import TeamForm from "../components/TeamForm";
import { ContingentDto, TeamDto } from "../models";
import { Navigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ContingentForm } from "../components/ContingentForm";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { Cancel } from "@mui/icons-material";

export default function TeamsPage() {
  const userContext = useContext(UserContext);
  const [tab, setTab] = useState<string>('team0');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>();
  const [error, setError] = useState<string>();
  const [createFormOpen, setCreateFormOpen] = useState<boolean>(false);
  const [contingentCreateFormOpen, setContingentCreateFormOpen] = useState<boolean>(false);

  const handleOpenCreateForm = useCallback(() => {
    if (!userContext.user) {
      return;
    }
    setCreateFormOpen(true);
  }, [userContext.user]);
  const handleCloseCreateForm = useCallback(() => {
    setCreateFormOpen(false);
  }, []);

  const handleOpenContingentCreateForm = useCallback(() => {
    if (!userContext.user) {
      return;
    }
    setContingentCreateFormOpen(true);
  }, [userContext.user]);
  const handleCloseContingentCreateForm = useCallback(() => {
    setContingentCreateFormOpen(false);
  }, []);
  
  const handleCreateTeam = useCallback((details: TeamDto) => {
    if (!userContext.user) {
      return;
    }
    setLoading(true);
    setSuccess(undefined);
    setError(undefined);
    userContext.createTeam(details).then(() => {
      setCreateFormOpen(false);
      setSuccess('Pasukan berjaya ditambah.');
      setTimeout(() => setSuccess(undefined), 6000);
    }).catch((reason: Error) => {
      setError(reason.message);
      setTimeout(() => setError(undefined), 6000);
    }).finally(() => {
      setLoading(false);
    });
  }, [userContext]);

  const handleCreateContingent = useCallback((details: ContingentDto) => {
    if (!userContext.user) {
      return;
    }
    setLoading(true);
    setSuccess(undefined);
    setError(undefined);
    userContext.createContingent(details).then(() => {
      setContingentCreateFormOpen(false);
      setSuccess('Kontinjen berjaya ditambah.');
      setTimeout(() => setSuccess(undefined), 6000);
    }).catch((reason: Error) => {
      setError(reason.message);
      setTimeout(() => setError(undefined), 6000);
    }).finally(() => {
      setLoading(false);
    });
  }, [userContext]);

  const handleSaveTeam = useCallback((teamId: string, details: TeamDto) => {
    if (!userContext.user) {
      return;
    }
    setLoading(true);
    setSuccess(undefined);
    setError(undefined);
    userContext.updateTeam(teamId, details).then(() => {
      setSuccess('Pasukan berjaya dikemaskini.');
      setTimeout(() => setSuccess(undefined), 6000);
    }).catch((error: Error) => {
      setError(JSON.stringify(error));
      setTimeout(() => setError(undefined), 6000);
    }).finally(() => {
      setLoading(false);
    });
  }, [userContext]);

  const handleDeleteTeam = useCallback((teamId: string) => {
    if (!userContext.user) {
      return;
    }
    setLoading(true);
    setSuccess(undefined);
    setError(undefined);
    userContext.deleteTeam(teamId).then(() => {
      setSuccess('Pasukan berjaya dipadam.');
      setTimeout(() => setSuccess(undefined), 6000);
    }).catch((error: Error) => {
      setError(JSON.stringify(error));
      setTimeout(() => setError(undefined), 6000);
    }).finally(() => {
      setLoading(false);
    });
  }, [userContext]);

  return !!userContext.user ? (
    <Stack spacing={2}>
      <Typography variant="h2" noWrap>Perihal Pasukan</Typography>
      {!!success && <Snackbar open={!!success} onClick={() => setSuccess(undefined)}><Alert severity='success' variant='filled'>{success}</Alert></Snackbar>}
      {!!error && <Snackbar open={!!error} onClick={() => setError(undefined)}><Alert severity='error' variant='filled'>{error}</Alert></Snackbar>}
      <Stack spacing={2}>
        <Button disabled={loading} onClick={handleOpenContingentCreateForm}>Tambah Kontinjen</Button>
        <Button disabled={loading} onClick={handleOpenCreateForm}>Tambah Pasukan</Button>
        {userContext.teams.length > 0 && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={(_, v) => setTab(v)}>
                {userContext.teams.map((_, i) => <Tab key={`teamtab${i}`} label={`Pasukan #${i + 1}`} value={`team${i}`} />)}
              </Tabs>
            </Box>
            {userContext.teams.map((t, i) => (
              <CustomTabPanel key={`teamformpanel${i}`} currentValue={tab} value={`team${i}`}>
                <TeamForm
                  key={`teamform${i}`}
                  disabled={loading}
                  teamId={t.id}
                  onSave={d => handleSaveTeam(t.id, d)}
                  onDelete={() => handleDeleteTeam(t.id)}
                  defaultValue={{
                    contingentId: t.contingentId,
                    categoryId: t.categoryId,
                    managerId: userContext.user?.id ?? '',
                    members: userContext.teamMembers.filter(m => m.team === t.id)
                  }}
                />
              </CustomTabPanel>
            ))}
          </Box>
        )}
        <Dialog open={createFormOpen} onClose={handleCloseCreateForm}>
          <DialogTitle>Tambah Pasukan</DialogTitle>
          <DialogContent>
            <TeamForm
              disabled={loading}
              onSave={d => handleCreateTeam(d)}
              onCancel={handleCloseCreateForm}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={contingentCreateFormOpen} onClose={handleCloseContingentCreateForm}>
          <DialogTitle>Tambah Kontinjen</DialogTitle>
          <DialogContent>
            <ContingentForm
              disabled={loading}
              onSave={d => handleCreateContingent(d)}
              onCancel={handleCloseContingentCreateForm}
            />
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  ) : <Navigate to='/daftar-masuk' replace />;
}