import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { UserContext } from '../contexts/UserContext';
import { UserType } from '../enums';
import { Person } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';



export default function SiteHeader() {
  const userContext = useContext(UserContext);

  const [pages, setPages] = useState<{ href: string, name: string }[]>([]);
  const [settings, setSettings] = useState<{ href: string, name: string }[]>([]);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const newPages: { href: string, name: string }[] = [];
    const newSettings: { href: string, name: string }[] = [];
    if (!!userContext.token) {
      newSettings.push({
        href: '/pengguna',
        name: 'Pengguna'
      });
    }
    if (!!userContext.user && userContext.user.type === UserType.TeamManager) {
      newPages.push(
        {
          href: '/pasukan',
          name: 'Pasukan'
        },
        {
          href: '/acara',
          name: 'Acara'
        }
      );
    }
    newPages.push({
      href: '/peraturan-kejohanan',
      name: 'Peraturan Kejohanan'
    });
    newSettings.push({
      href: '/tetapan',
      name: 'Tetapan'
    });
    if (!!userContext.token) {
      newSettings.push({
        href: '/log-keluar',
        name: 'Log Keluar'
      });
    } else {
      newSettings.push(
        {
          href: '/log-masuk',
          name: 'Log Masuk'
        },
        {
          href: '/daftar',
          name: 'Daftar'
        }
      );
    }
    setPages(newPages);
    setSettings(newSettings);
  }, [userContext]);

  return (
    <AppBar position="static" color='primary' enableColorOnDark sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <IconButton onClick={handleOpenNavMenu} color="inherit" sx={{ height: '100%', padding: '10px' }}>
        <MenuIcon />
      </IconButton>
      <Stack spacing={2} direction='row' alignItems='center' flex={1}>
        <IconButton size='large' component={RouterLink} to='/'>
          <img alt='Logo' src='/assets/images/logo.png' style={{ width: '50px', padding: '2px' }} />
        </IconButton>
        <Typography variant='h6' noWrap color='inherit' sx={{ display: { xs: 'none', md: 'flex' }, textDecoration: 'none' }} component={RouterLink} to='/'>Kejohanan Silat Cekak Hanafi Nasional {new Date().getFullYear()}</Typography>
        <Typography variant='h6' noWrap color='inherit' sx={{ display: { md: 'none', xs: 'flex' }, textDecoration: 'none' }} component={RouterLink} to='/'>KSCHN{new Date().getFullYear()}</Typography>
      </Stack>
      <IconButton size="large" onClick={handleOpenUserMenu} color="inherit" sx={{ height: '100%', padding: '10px' }}>
        <Person />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="user-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem component={RouterLink} key={`setting-${setting.name.replace(' ', '-')}`} to={setting.href} onClick={handleCloseUserMenu}>
            <Typography textAlign="center">{setting.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
      >
        {pages.map((page) => (
          <MenuItem component={RouterLink} key={`page-${page.name.replace(' ', '-')}`} to={page.href} onClick={handleCloseNavMenu}>
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
}