import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import SignInForm from "../components/SignInForm";
import { Navigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import LinkButton from "../components/LinkButton";
import { Credentials } from "../models";

export default function SignInPage() {
  const [error, setError] = useState<string>();
  const [credentials, setCredentials] = useState<Credentials>();
  const userContext = useContext(UserContext);

  const handleSignIn = useCallback((nric: string | undefined, password: string | undefined) => {
    setError(undefined);
    if (!nric || !password) {
      return;
    }
    setCredentials({nric, password});
  }, []);

  useEffect(() => {
    if (!credentials) {
      return;
    }
    userContext.signIn(credentials).catch((err: Error) => {
      setError(err.message);
      setTimeout(() => setError(undefined), 6000);
    }).finally(() => {
      setCredentials(undefined);
    });
  }, [credentials, userContext]);

  return (
    <Stack spacing={2} padding={2}>
      <Typography variant='h2' noWrap>Log Masuk</Typography>
      <Typography>Kali pertama di laman ini? Sila klik <LinkButton to='daftar' label='di sini' />.</Typography>
      {!!error && <Snackbar open><Alert severity='error' variant='filled'>{error}</Alert></Snackbar>}
      {!!credentials && <CircularProgress />}
      <SignInForm onSubmit={handleSignIn} disabled={!!credentials} />
      {userContext.token && <Navigate to='/' replace />}
    </Stack>
  );
}