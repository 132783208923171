import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export function TeamPoints() {
  return (
    <>
      <Typography>Johan keseluruhan bagi setiap peringkat ditentukan berdasarkan jumlah pungutan mata bagi kesemua penyertaan acara Kategori Utama.</Typography>
      <Typography>Mata bagi setiap tempat untuk setiap acara dalam setiap peringkat:</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tempat</TableCell>
              <TableCell>Mata</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Johan</TableCell>
              <TableCell>9</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Naib Johan</TableCell>
              <TableCell>7</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tempat Ketiga</TableCell>
              <TableCell>5</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tempat Keempat</TableCell>
              <TableCell>3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tempat Kelima</TableCell>
              <TableCell>2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tempat Keenam</TableCell>
              <TableCell>1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tempat Ketujuh</TableCell>
              <TableCell>1</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}