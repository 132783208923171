import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { Category, Contingent, ContingentDto, Credentials, DataStore, EventEntry, SessionToken, SignUpDetails, Team, TeamDto, TeamMember, TournamentEvent, User } from "../models";
import { UserDataStore } from "../data/UserDataStore";
import { RestDataStore } from "../data/RestDataStore";

type UserFunctions = {
  contingents: Contingent[];
  categories: Category[];
  token: SessionToken | undefined;
  user: User | undefined;
  teams: Team[];
  teamMembers: TeamMember[];
  events: TournamentEvent[];
  mainEvents: TournamentEvent[];
  openEvents: TournamentEvent[];
  entries: EventEntry[];
  signIn: (credentials: Credentials) => Promise<boolean>;
  signOut: () => Promise<boolean>;
  signUp: (details: SignUpDetails) => Promise<boolean>;
  createContingent: (v: ContingentDto) => Promise<Contingent | undefined>;
  createTeam: (v: TeamDto) => Promise<Team | undefined>;
  updateTeam: (id: string, v: TeamDto) => Promise<Team | undefined>;
  updateEntries: (newEntries: EventEntry[]) => Promise<EventEntry[]>;
  deleteTeam: (id: string) => Promise<boolean>;
};

export const UserContext = createContext<UserFunctions>({
  contingents: [],
  categories: [],
  token: undefined,
  user: undefined,
  teams: [],
  teamMembers: [],
  events: [],
  mainEvents: [],
  openEvents: [],
  entries: [],
  signIn: () => new Promise<boolean>(resolve => resolve(false)),
  signOut: () => new Promise<boolean>(resolve => resolve(false)),
  signUp: () => new Promise<boolean>(resolve => resolve(false)),
  createContingent: () => new Promise<Contingent | undefined>(resolve => resolve(undefined)),
  createTeam: () => new Promise<Team | undefined>(resolve => resolve(undefined)),
  updateTeam: () => new Promise<Team | undefined>(resolve => resolve(undefined)),
  updateEntries: () => new Promise<EventEntry[]>(resolve => resolve([])),
  deleteTeam: () => new Promise<boolean>(resolve => resolve(false))
});

export function UserProvider({children}: {children: ReactNode}) {
  const [token, setToken] = useState<SessionToken>();
  const [user, setUser] = useState<User>();
  const [teams, setTeams] = useState<Team[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [events, setEvents] = useState<TournamentEvent[]>([]);
  const [entries, setEntries] = useState<EventEntry[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [contingents, setContingents] = useState<Contingent[]>([]);
  
  const categoryDb: DataStore<Category, Omit<Category, 'id'>> = useMemo(() => new RestDataStore<Category, Omit<Category, 'id'>>('category', token?.token), [token?.token]);
  const contingentDb: DataStore<Contingent, ContingentDto> = useMemo(() => new RestDataStore<Contingent, ContingentDto>('contingent', token?.token), [token?.token]);
  const userDb: UserDataStore = useMemo(() => new UserDataStore(token?.token), [token?.token]);
  const eventDb: DataStore<TournamentEvent, Omit<TournamentEvent, 'id'>> = useMemo(() => new RestDataStore<TournamentEvent, Omit<TournamentEvent, 'id'>>('tournament-event', token?.token), [token?.token]);
  const teamDb: DataStore<Team, TeamDto> = useMemo(() => new RestDataStore<Team, TeamDto>('team', token?.token), [token?.token]);
  const entryDb: DataStore<EventEntry, Omit<EventEntry, 'id'>> = useMemo(() => new RestDataStore<EventEntry, Omit<EventEntry, 'id'>>('event-entry', token?.token), [token?.token]);

  const mainEvents = useMemo(() => events.filter(x => !x.open), [events]);
  const openEvents = useMemo(() => events.filter(x => !!x.open), [events]);
  
  const signIn = useCallback(async (credentials: Credentials) => {
    const result = await userDb.login(credentials);
    if (!!result) {
      setToken(result);
    }
    return !!result;
  }, [userDb]);

  const signOut = useCallback(async () => {
    setToken(undefined);
    return true;
  }, []);
  const signUp = useCallback(async (details: SignUpDetails) => {
    const newUser = await userDb.create(details);
    if (!newUser) {
      throw new Error('Invalid sign-up details.');
    }
    return true;
  }, [userDb]);

  const createContingent = useCallback(async (details: ContingentDto) => {
    const newContingent = await contingentDb.create(details);
    if (!newContingent) {
      throw new Error('Invalid contingent details.');
    }
    const contingents = await contingentDb.getAll();
    setContingents(contingents);
    return newContingent;
  }, [contingentDb]);

  const createTeam = useCallback(async (v: TeamDto) => {
    const newTeam = await teamDb.create(v);
    if (!newTeam) {
      throw new Error('Invalid team details.');
    }
    if (!!token) {
      teamDb.getAll(x => x.managerId === token.id).then(result => {
        setTeams(result);
      });
    } else {
      setTeams([]);
    }
    return newTeam;
  }, [teamDb, token]);
  
  const updateTeam = useCallback(async (id: string, v: TeamDto) => {
    const userId = token?.id;
    if (!userId) {
      throw new Error('User does not exist.');
    }
    const newTeam = await teamDb.update(id, {
      categoryId: v.categoryId,
      managerId: userId,
      members: v.members
    });
    if (!newTeam) {
      throw new Error('Invalid team details.');
    }
    const newTeams = await teamDb.getAll(x => x.managerId === token.id);
    setTeams(newTeams);
    return newTeams.find(x => x.id === id);
  }, [teamDb, token]);

  const updateEntries = useCallback(async (newEntries: EventEntry[]) => {
    const userId = token?.id;
    if (!userId) {
      throw new Error('User does not exist.');
    }
    const oldEntries = entries.map(x => x.id);
    const transactions = [
      ...newEntries.map(x => !oldEntries.includes(x.id) ? entryDb.create(x) : entryDb.update(x.id, x)),
      ...oldEntries.filter(x => newEntries.map(y => y.id).includes(x)).map(x => entryDb.delete(x))
    ];
    await Promise.all(transactions);
    const newTeamIds = teams.map(t => t.id);
    const newEvents = await entryDb.getAll(x => newTeamIds.includes(x.team));
    setEntries(newEvents);
    return newEvents;
  }, [entryDb, teams, token, entries]);

  const deleteTeam = useCallback(async (id: string) => {
    const userId = token?.id;
    if (!userId) {
      throw new Error('User does not exist.');
    }
    const transactions: Promise<boolean>[] = [];
    const eventsToDelete = await entryDb.getAll(x => x.team === id);
    transactions.push(...eventsToDelete.map(x => entryDb.delete(x.id)));
    transactions.push(teamDb.delete(id));
    await Promise.all(transactions);
    return true;
  }, [teamDb, entryDb, token]);

  useEffect(() => {
    eventDb.getAll().then(e => {
      setEvents(e);
    }).catch(e => {
      console.error(e);
      setEvents([]);
    })
  }, [eventDb]);

  useEffect(() => {
    if (!!token) {
      teamDb.getAll(x => x.managerId === token.id).then(result => {
        setTeams(result);
      });
    } else {
      setTeams([]);
    }
  }, [token, teamDb]);

  useEffect(() => {
    if (teams.length < 1) {
      setTeamMembers([]);
    } else {
      setTeamMembers(teams.map(x => x.members).reduce((a, b) => [...a, ...b]))
    }
  }, [teams]);

  useEffect(() => {
    if (teams.length < 1) {
      setEntries([]);
    } else {
      const eventIds = teams.map(t => t.id);
      entryDb.getAll(x => eventIds.includes(x.team)).then(result => {
        setEntries(result);
      });
    }
  }, [teams, entryDb]);

  useEffect(() => {
    categoryDb.getAll().then(result => {
      setCategories(result);
    });
    contingentDb.getAll().then(result => {
      setContingents(result);
    });
    eventDb.getAll().then(result => {
      setEvents(result);
    });
  }, [categoryDb, contingentDb, eventDb]);

  useEffect(() => {
    if (!!token && !user) {
      userDb.get(token.id).then(result => {
        setUser(result);
      });
    } else if (!token) {
      setUser(undefined);
    }
  }, [token, user, userDb]);

  return (
    <UserContext.Provider value={{
      contingents,
      categories,
      token,
      user,
      teams,
      teamMembers,
      events,
      mainEvents,
      openEvents,
      entries,
      signIn,
      signOut,
      signUp,
      createContingent,
      createTeam,
      updateTeam,
      updateEntries,
      deleteTeam
    }}>
      {children}
    </UserContext.Provider>
  );
}