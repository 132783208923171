import TableContainer from "@mui/material/TableContainer";
import { TournamentEvent } from "../../models";
import { PrefabAccordion } from "../PrefabAccordion";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { AccordionProps } from "@mui/material/Accordion";

export function EventDetails(
  props: AccordionProps & {
    event: TournamentEvent,
    index: number
  }
) {
  return (
    <PrefabAccordion {...props} title={`Acara ${props.index + 1}: ${props.event.name}`}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Perkara</TableCell>
              <TableCell>Butiran</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Bilangan peserta</TableCell>
              <TableCell>{props.event.participants} orang</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Had masa</TableCell>
              <TableCell>{props.event.timeLimit} saat</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lain-lain butiran</TableCell>
              <TableCell>{props.children}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PrefabAccordion>
  );
}