import { Circle } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export function TournamentEquipments() {
  return (
    <>
      <Typography>Bagi setiap arena:</Typography>
      <List>
        <ListItem>
          <ListItemIcon><Circle /></ListItemIcon>
          <ListItemText>Kawasan pertandingan seluas 25 meter persegi.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon><Circle /></ListItemIcon>
          <ListItemText>Ruang seluas 2 meter di sekeliling arena bagi urusan teknikal.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon><Circle /></ListItemIcon>
          <ListItemText>Papan sekeping dengan tinggi 15 sentimeter, lebar 23 sentimeter & panjang 3.6 meter.</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <Typography>Bagi setiap peserta:</Typography>
      <List>
        <ListItem>
          <ListItemIcon><Circle /></ListItemIcon>
          <ListItemText>Peserta acara-acara tamat kecuali hero Acara 9: uniform lengkap.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon><Circle /></ListItemIcon>
          <ListItemText>Peserta hero Acara 9: Uniform lengkap, tetapi digalakkan untuk memakai busana lengkap.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon><Circle /></ListItemIcon>
          <ListItemText>Peserta acara-acara belum tamat: T-shirt latihan rasmi & seluar latihan rasmi berwarna biru gelap.</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <Typography>Uniform lengkap:</Typography>
      <img alt='Uniform lengkap' src='/assets/images/uniform-lengkap.jpg' width='200px' height='200px' />
      <Typography>T-shirt latihan rasmi:</Typography>
      <img alt='T-shirt latihan rasmi' src='/assets/images/tshirt-latihan.jpg' width='200px' height='200px' />
    </>
  );
}